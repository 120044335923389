import React from "react";

function Schedule() {
  return (
    <section id="schedules" className="py-12 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Cek Jadwal & Tarif</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg">
            <thead>
              <tr className="bg-primary text-white">
                <th className="py-3 px-4">Rute</th>
                <th className="py-3 px-4">Jam Keberangkatan</th>
                <th className="py-3 px-4">Tarif</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center border-b">
                <td className="py-3 px-4">Bandung - Lampung PP</td>
                <td className="py-3 px-4">17:00 WIB</td>
                <td className="py-3 px-4">
                  <a
                    href="https://wa.me/+6285159744078?text=Halo,%20saya%20ingin%20tanya%20layanan%20travel%20ini." // Ganti dengan nomor WhatsApp Anda
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-primary-dark transition duration-300"
                  >
                    Cek Harga
                  </a>
                </td>
              </tr>
              <tr className="text-center border-b">
                <td className="py-3 px-4">Jakarta - Lampung PP</td>
                <td className="py-3 px-4">17:00 WIB</td>
                <td className="py-3 px-4">
                  <a
                    href="https://wa.me/+6285159744078?text=Halo,%20saya%20ingin%20tanya%20layanan%20travel%20ini." // Ganti dengan nomor WhatsApp Anda
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-primary-dark transition duration-300"
                  >
                    Cek Harga
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="text-center mt-6">
        <a
            href="https://wa.me/+6285159744078?text=Halo,%20saya%20ingin%20memesan%20layanan%20travel%20ini." // Ganti dengan nomor WhatsApp Anda
            target="_blank"
            rel="noopener noreferrer"
            className="bg-primary text-white px-6 py-3 rounded-full font-semibold hover:bg-primary-dark transition duration-300"
          >
            Pesan Sekarang
          </a>
        </div>
      </div>
    </section>
  );
}

export default Schedule;