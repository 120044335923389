import React from "react";

function Hero() {
  return (
    <section
      id="home"
      className="bg-cover bg-center h-screen flex items-center"
      style={{ backgroundImage: "url('/hiace-bg.jpeg')" }}
    >
      <div className="bg-black bg-opacity-50 w-full h-full flex items-center">
        <div className="container mx-auto px-6 text-center text-white">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Travel Nyaman, Aman, dan Tepat Waktu
          </h1>
          <p className="mb-8 text-lg">
            Nikmati layanan antar jemput dari pintu ke pintu rute Bandung - Jakarta - Lampung PP
          </p>
          <a
            href="https://wa.me/+6285159744078?text=Halo,%20saya%20ingin%20memesan%20layanan%20travel%20ini." // Ganti dengan nomor WhatsApp Anda
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-gray-100"
          >
            Pesan Sekarang
          </a>
        </div>
      </div>
    </section>
  );
}

export default Hero;